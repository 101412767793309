<template>
  <section class="section" :class="color ? `section--${color}` : '' ">
      <div class="container">
          <slot/>
      </div>
  </section>
</template>

<script>
export default {
    props:['color']
}
</script>

<style lang='scss' scoped>
@import '../style/main';
.section{
    &--grey{
        background-color: $color-background-grey;
    }
    &--white{
        background-color: $color-white;
    }
    @include respond(tab-land){
        padding: 5rem;
    }

    @include respond(phone){
        padding: 2rem;
    }
    
}
.container{
    max-width: $page-max-width;
    margin: 0 auto;
    padding: 6rem 0;

    @include respond(phone){
        margin: 0 $margin-section-phone;
    }
}

</style>